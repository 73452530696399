.input-label {
    font-size: 14px;
    color: #3f4254;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
}

.default-input {
    border: 1px solid #f0f0f0 !important;
    /* height: 40px; */
}

.default-input:focus {
    border: 1px solid rgb(213, 213, 213) !important;
}

.border-red {
    border: 1px solid #bc0707 !important;
    box-shadow: 0 0 5px rgba(188, 7, 7, 0.5);
}



.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__month-container {
    width: 250px;
}
.react-datepicker__current-month{
    display: none;
}
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
    font-size: 13px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2.7rem !important;
    line-height: 2.7rem !important;
    font-size: 13px;
}